<template>
    <div class=" d-flex ">
        <div>
            <ActionSave class="btn btn-sm btn-primary" :dataObject="props.dataObject">Save</ActionSave>
            <ActionCancel class="btn btn-sm btn-secondary ms-1" :dataObject="props.dataObject">Cancel</ActionCancel>
            <!--<ActionDelete class="ms-3 btn btn-sm btn-light" :dataObject="props.dataObject">Delete</ActionDelete>-->
        </div>
        <slot></slot>
    </div>
</template>


<script setup> 
    import { computed } from 'vue';
    import OTab from "o365.vue.components.Tab.vue";
    import ActionSave from "o365.vue.components.Action.Save.vue"
    import ActionCancel from "o365.vue.components.Action.Cancel.vue"
    import ActionDelete from "o365.vue.components.Action.Delete.vue"
    import { ref, onUpdated, onBeforeUpdate, onMounted, useSlots } from 'vue';
   
    
    const props = defineProps(
        {
            dataObject:{
                type: Object,
                default:undefined    
            },
            row: {
                type: Object,
                default: undefined
            }
        }
    );

    
    onMounted(() => {
        
        
    });


</script>   

